.hamburgerOrganisms {
  position: relative;
}

.hamburgerContentContainer {
  display: block;
  position: absolute;
  background: #111;
  color: #a2aabd;
  top: .52rem;
  right: 0;
  z-index: 2;
  height: 0;
  transition: height .5s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
}

@media (max-width: 1024px) {
  .hamburgerContentContainer {
    top: .82rem;
  }
}

.hamburgerContentContainer.fadeIn {
  min-height: 6rem;
  height: auto;
}

.hamburgerContentContainer.fadeOut {
  height: 0;
}

.hamburgerContent {
  display: grid;
  border-radius: 0 0 .1rem .1rem;
  grid-template-columns: auto 55.136268%;
  width: 5.53rem;
  z-index: 1;
  padding: .28rem .31rem .39rem .48rem;
}

.left {
  padding-top: .23rem;
}

.right {
  background: linear-gradient(180deg, rgba(41, 43, 46, 0.4) 0%, rgba(17, 17, 17, 0) 126.7%);
  border-radius: .1rem .1rem 0 0;
  padding: .23rem 0 .28rem .34rem;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}